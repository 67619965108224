var columns = [{
  title: '商家名称',
  dataIndex: 'username',
  key: 'username',
  // width: '7%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '商家类型',
  dataIndex: 'userTypeName',
  key: 'userTypeName',
  // width: '5%',
  scopedSlots: {
    customRender: 'userTypeName'
  }
}, {
  // title: '商家',
  dataIndex: 'email',
  key: 'email',
  // width: '10%',
  scopedSlots: {
    customRender: 'email'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '待结算($)',
  dataIndex: 'waitSettle',
  key: 'waitSettle',
  // width: '10%',
  scopedSlots: {
    customRender: 'waitSettle'
  }
}, {
  title: '已结算($)',
  dataIndex: 'settle',
  key: 'settle',
  // width: '10%',
  scopedSlots: {
    customRender: 'settle'
  }
}, {
  title: '已失效($)',
  dataIndex: 'lapse',
  key: 'lapse',
  // width: '10%',
  scopedSlots: {
    customRender: 'lapse'
  }
}, {
  title: '首次出售时间',
  dataIndex: 'firstselltime',
  key: 'firstselltime',
  // width: '5%',
  scopedSlots: {
    customRender: 'firstselltime'
  }
}, {
  title: '操作',
  dataIndex: 'actions',
  key: 'actions',
  // fixed: "right",
  // width: '130',
  scopedSlots: {
    customRender: 'actions'
  }
}];
var waitColumns = [{
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '8%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '19%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '商品',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '商品售价（$）',
  dataIndex: 'salesprice',
  key: 'salesprice',
  width: '13%',
  scopedSlots: {
    customRender: 'salesprice'
  }
}, {
  title: '数量',
  dataIndex: 'num',
  key: 'num',
  width: '9%',
  scopedSlots: {
    customRender: 'num'
  }
}, {
  title: '预计收入（$）',
  dataIndex: 'revenue',
  key: 'revenue',
  // width: '25%',
  scopedSlots: {
    customRender: 'revenue'
  },
  ellipsis: true
}];
var settleColumns = [{
  title: '结算时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '8%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '19%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '流水号',
  dataIndex: 'flowNo',
  key: 'flowNo',
  // width: '19%',
  scopedSlots: {
    customRender: 'flowNo'
  }
}, {
  title: '商品',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '商品售价（$）',
  dataIndex: 'salesprice',
  key: 'salesprice',
  // width: '13%',
  scopedSlots: {
    customRender: 'salesprice'
  }
}, {
  title: '数量',
  dataIndex: 'num',
  key: 'num',
  // width: '9%',
  scopedSlots: {
    customRender: 'num'
  }
}, {
  title: '结算方式',
  dataIndex: 'payTypeName',
  key: 'payTypeName',
  // width: '9%',
  scopedSlots: {
    customRender: 'payTypeName'
  }
}, {
  title: '预计收入（$）',
  dataIndex: 'revenue',
  key: 'revenue',
  // width: '25%',
  scopedSlots: {
    customRender: 'revenue'
  },
  ellipsis: true
}];
var lapseColumns = [{
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '8%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '19%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '商品',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '商品售价（$）',
  dataIndex: 'salesprice',
  key: 'salesprice',
  // width: '13%',
  scopedSlots: {
    customRender: 'salesprice'
  }
}, {
  title: '数量',
  dataIndex: 'num',
  key: 'num',
  // width: '9%',
  scopedSlots: {
    customRender: 'num'
  }
}, {
  title: '失效收入（$）',
  dataIndex: 'revenue',
  key: 'revenue',
  // width: '25%',
  scopedSlots: {
    customRender: 'revenue'
  },
  ellipsis: true
}, {
  title: '失效原因',
  dataIndex: 'closingreason',
  key: 'closingreason',
  // width: '25%',
  scopedSlots: {
    customRender: 'closingreason'
  },
  ellipsis: true
}];
export { columns, waitColumns, settleColumns, lapseColumns };